import React from "react";
import Lottie from "react-lottie";

const LottieAnim = ({
  animationData,
  loop = true,
  width = 100,
  height = 100
}) => {
  const defaultOptions = {
    loop: loop,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div className="">
      <Lottie
        options={defaultOptions}
        width={width + "%"}
        height={height + "%"}
      />
    </div>
  );
};

export default LottieAnim;
