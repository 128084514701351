import React from 'react'
import CookieConsent from 'react-cookie-consent'

const CookConsent = () => {
  return (
    <div>
      <CookieConsent
        location='bottom'
        buttonText='Accept'
        expires={365}
        disableButtonStyles={true}
        buttonClasses='button is-primary is-outlined'
        containerClasses='notification'
        contentClasses='is-capitalized is-size-5-desktop is-size-6-mobile'
      >
        This website uses cookies to enhance the user experience.{' '}
        <span>
          <a href='https://www.cookiesandyou.com' className='has-text-primary'>
            Learn more{' '}
          </a>
        </span>
      </CookieConsent>
    </div>
  )
}

export default CookConsent
