import React, { useEffect } from 'react'
import animationData from '../lotties/lottiefiles/410-lego-loader'
import LottieAnim from '../lotties/LottieAnim'

const Preloader = () => {
  useEffect(() => {
    document.body.classList.remove('has-navbar-fixed-top')
    return () => {
      document.body.classList.add('has-navbar-fixed-top')
    }
  }, [])
  return (
    <section className='hero is-white is-fullheight'>
      <div className='hero-body'>
        <div className='container center'>
          <LottieAnim animationData={animationData}></LottieAnim>
          {/* <button className='button is-large is-text is-loading'>
            <span className='icon is-medium'>
              <i className='fas fa-spin fa-3x'></i>
            </span>
          </button> */}
        </div>
      </div>
    </section>
  )
}

export default Preloader
