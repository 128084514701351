import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import './App.css'
import Preloader from './components/layout/Preloader'

// import HomePage from './pages/HomePage';
// import LoginPage from './pages/LoginPage';
// import NotFound404 from './pages/NotFound404';

import smoothscroll from 'smoothscroll-polyfill'
import CookConsent from './components/layout/CookConsent'

const HomePage = lazy(() => import('./pages/HomePage'))
// const LoginPage = lazy(() => import('./pages/LoginPage'));
const NotFound404 = lazy(() => import('./pages/NotFound404'))
const QRCodePage = lazy(() => import('./pages/QRCodePage'))

function App() {
  smoothscroll.polyfill()

  return (
    <BrowserRouter>
      <Suspense fallback={<Preloader />}>
        <div className='App'>
          <Switch>
            <Route exact path='/' component={HomePage} />
            {/* <Route path='/login' component={LoginPage} /> */}
            <Route path='/qrcode' component={QRCodePage} />
            <Route path='*' component={NotFound404} />
          </Switch>
          <CookConsent />
        </div>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
